$(function() {
    function orderTimer() {
        jQuery('.timer-box__counter').each(function () {
            var timerBox = jQuery(this);
            var minBox = timerBox.find('.timer-box__minutes');
            var secBox = timerBox.find('.timer-box__seconds');
            var minVal = 15;
            var secVal = 0;
            var deadline = new Date(Date.parse(new Date()) + (minVal * 60 + secVal) * 1000);

            function getTimeRemaining(endtime) {
                var t = Date.parse(endtime) - Date.parse(new Date());
                var seconds = Math.floor(t / 1000 % 60);
                var minutes = Math.floor(t / 1000 / 60 % 60);
                return {
                    'total': t,
                    'minutes': minutes,
                    'seconds': seconds
                };
            }

            function initializeClock(endtime) {
                function updateClock() {
                    var t = getTimeRemaining(endtime);
                    minBox.text(('0' + t.minutes).slice(-2));
                    secBox.text(('0' + t.seconds).slice(-2));

                    if (t.total <= 0) {
                        clearInterval(timeinterval);
                    }
                }

                updateClock();
                var timeinterval = setInterval(updateClock, 1000);
            }

            initializeClock(deadline);
        });
    }
    orderTimer();
});