var showImage = 0;
$('.order-summary-mobile__title').stop().on('click', function () {

    let $action  = $('.order-summary-mobile__action');
    let $content = $('.order-summary-mobile-content');

    if ($content.is(':visible')) {
        $action.text('+');
    } else {
        $action.text('-');
        if(showImage === 0) {
            showImage = 1;
            $('.order-summary-mobile__product-image').html('<img src="image/ceralift-1-bottle.png" alt="CeraLift Product" class="img-responsive">');
        }
    }
    $content.fadeToggle(300);
});