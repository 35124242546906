/**
 *  @file Simple Form Field Validator
 *
 *  @version    1.0.1
 *  @requires   GIT:Jquery
 */



/**
 * Validates form fields against regular expressions
 * @param fieldRules
 * @constructor
 */
export default class Validity {

    constructor(fieldRules, errorStyle) {

        if (typeof fieldRules !== 'object') {
            throw new Error('fieldRules is expected to be an Object and is required')
        }

        // Contains form field data
        this.formData = {};
        // Contains an array of form errors
        this.formError = [];
        // Rules for the form fields
        this.fieldRules = fieldRules;

        this.errorStyle = errorStyle || {'border': '1px solid #ff0000'}
    }

    // 1. Validate field against Field Rules
    validate() {

        // Clear
        this.formData  = {};
        this.formError = [];

        // TODO check fieldName hasOwnProperty

        // Loop through rules
        for (var fieldName in this.fieldRules) {

            // Select field
            var field = $('[name=' + fieldName + ']');

            // Get field value
            var value = field.val();

            if (fieldName === 'creditCardNumber') {
                // If credit card remove
                value = value.replace(/\D/g, '');
            }

            value = value.toLowerCase();

            // TODO add option for default css
            // Remove previous border styling
            field.css({
                'border': '1px solid #5da743'
            });

            // Get field rules
            var rule = this.fieldRules[fieldName]; // Add form data to object

            this.formData[fieldName] = value;

            // Check if Required
            if (rule.indexOf('required') !== -1) {
                if (value === '' || value == undefined) {
                    this.formError.push({
                        // field name
                        'fieldName'    : fieldName,
                        // failed rule
                        'failedRule'   : 'required',
                        // failed message
                        'failedMessage': 'Field Required'
                    });
                }
            }


            // Check if Credit Card Number
            if (rule.indexOf('credit card number') !== -1) {
                if (!/[0-9\-]/.test(value)) {
                    this.formError.push({
                        'fieldName'    : fieldName,
                        'failed_Rule'  : 'credit card number',
                        'failedMessage': 'Field should only contain Numbers & Hypens'
                    }); // console.log('field should only contain Numbers & Hypens');
                }
            }

            // Check if Number
            if (rule.indexOf('numbers') !== -1) {
                if (!/^\d+$/.test(value)) {
                    this.formError.push({
                        'fieldName'    : fieldName,
                        'failedRule'   : 'numbers',
                        'failedMessage': 'Field should only contain Numbers'
                    }); // console.log('field should only contain numbers');
                }
            }
            // Check if is numbers & letters
            if (rule.indexOf('letters') !== -1) {
                if (!/^[a-zA-Z]+$/.test(value)) {
                    this.formError.push({
                        'fieleName'    : fieldName,
                        'failedRule'   : 'letters',
                        'failedMessage': 'Field should only contain Letters'
                    });
                }
            }

            // Check if is numbers & letters
            if (rule.indexOf('numbers letters spaces periods hypens') !== -1) {
                if (!/^[0-9a-zA-Z\s-.]+$/.test(value)) {
                    this.formError.push({
                        'fieldName'    : fieldName,
                        'failedRule'   : 'numbers letters',
                        'failedMessage': 'Field should only contain Numbers, Letters, Periods, Spaces & Hypens'
                    });
                }
            }

            // Check if it is Email Address
            if (rule.indexOf('email') !== -1) {

                // Remove first and last spaces if found
                value = value.trim();

                if (!/^([\w_\.\-\+])+\@([\w\-]+\.)+([\w]{2,10})+$/.test(value)) {
                    this.formError.push({
                        'fieldName'    : fieldName,
                        'failedRule'   : 'email',
                        'failedMessage': 'Field should be formatted like an Email Address'
                    });
                }
            }

            // Check if Email Address matches
            if (rule.indexOf('match') !== -1) {

                if ($('[name=emailAddress]').val() !== $('[name=confirmEmailAddress]').val()) {
                    this.formError.push({
                        'fieldName'    : fieldName,
                        'failedRule'   : 'match',
                        'failedMessage': 'Field should match Email Address'
                    });
                }
            }
        }

        this.error();
    }

    // 2. Check for errors return boolean
    error() {

        var errorField = $('.error-message');

        if (this.formError.length) {
            for (var error in this.formError) {
                // Select & add border to field with error
                var fieldSelect = $('[name=' + this.formError[error].fieldName + ']');
                // Add Red borders around
                fieldSelect.css(this.errorStyle);

                // Detect first found Error
                if (error == 0) {
                    // Focus on the first field in form that contains Error
                    fieldSelect.focus();
                    // Show Error Div
                    errorField.css({
                        'display': 'block'
                    });
                    // Insert Error Message
                    errorField.html(this.formError[error].failedMessage);
                    // Scroll Page to Error Message Div
                    errorField.animate({
                        scrollTop: 0
                    }, "slow");
                }
            }

        } else {
            // Clear error message
            errorField.html('');
            // Hide Error Message Div
            errorField.css({
                'display': 'none'
            });
        }
    }

    // 3. Return back if form is valid or not
    valid() {
        return this.formError.length === 0;
    }

    // Returns back formData
    data() {
        return this.formData;
    }

}
