import Cookie from './cookie'


export default class Parameter extends Cookie {

    constructor() {
        // Calls the parent Constructor
        super()

        this._ALLOWED_PARAMS = ['funnel','experiment','mail','Clickid', 'sxid', 'offerid', 'tid', 'pxsite', 'fbpxid',
            'cid', 'subid', 'hitid', 's1', 's2', 's3', 's4', 's5', 'utm_campaign', 'utm_content', 'utm_medium',
            'utm_source', 'utm_term', 'utm_expid', 'utm_referrer', 'affid', 'a', 'r', 'o', 't', 'purchased',
            'customer_email', 'token', 'placement', 'source', 'interest', 'age', 'gender', 'ad', 'ad_name',
            'campaign_name','paypal_billing_agreement_id','paypal_token_id', 'afid', 'sid', 'click_id', 'gclid', 'uuid',
            'google_optimize', 'universal_analytics']

        this._filter()
    }

    // Read Parameters
    _filter() {

        // Utility methods to work with the query string of a URL
        const _SEARCH_PARAMS = new URLSearchParams(window.location.search)

        let _found = {}

        let _fmsParameters  = this._getCookie('fms_parameters')

        if(_fmsParameters) {

            // Read current Cookie
            let _setCookieValues = JSON.parse(_fmsParameters)

            // Add Search Query from Cookie
            for(const [key, value] of Object.entries(_setCookieValues)) {
                // Check if URL query has value
                if(_SEARCH_PARAMS.get(key) === null) {
                    // Append additional search query parameters
                    _SEARCH_PARAMS.set(key, value)
                }
            }

        }



        // Compare to white list
        _SEARCH_PARAMS.forEach((value, property) => {

            // Check if parameter is allowed
            if(this._ALLOWED_PARAMS.indexOf(property) === -1) {
                return
            }

            // Check if property has value
            if((value == null || value.length === 0)) {

                // Check if Previous Cookie Property is set
                if(_setCookieValues.hasOwnProperty(property)) {
                    // TODO fix so value assignment only happens in one place
                    _found[property] = _setCookieValues[property]
                    return
                }

                return

            }

            _found[property] = value

        })



        // TODO export to own method
        if(window.hasOwnProperty('FUNNEL_SETTINGS')) {
            // TODO refactor
            if(FUNNEL_SETTINGS.hasOwnProperty('universalAnalytics')) {
                _found['universal_analytics'] = FUNNEL_SETTINGS.universalAnalytics

                // Check if gaData is available
                if(!window.hasOwnProperty('gaData')) {
                    return
                }

                if(gaData.hasOwnProperty(FUNNEL_SETTINGS.universalAnalytics)) {

                    // Avoid overwrite of google optimize
                    if(!_found['google_optimize']) {

                        // Try and look for backup identifiers
                        let _gaExpCookieValue = this._getCookie('_gaexp')

                        if(_gaExpCookieValue) {
                            let _splitGaExpCookieValue = _gaExpCookieValue.split('.')
                            _found['google_optimize'] = `${_splitGaExpCookieValue[2]}:${_splitGaExpCookieValue[4]}`
                        } else {
                            // Check gaData
                            if(gaData[FUNNEL_SETTINGS.universalAnalytics].hasOwnProperty('experiments')) {
                                _found['google_optimize']  = JSON.stringify(gaData[FUNNEL_SETTINGS.universalAnalytics].experiments).replace(/[{|}|"_]/g,'')
                            } else {
                                // Couldn't find anything to use in google_optimize
                                _found['google_optimize']  = false
                            }

                        }

                    }


                }

            }

        }


        console.log(_found)



        this._setCookie('fms_parameters', JSON.stringify(_found), 7)


    }

    /**
     * Converts object into a URL query string
     * @param _object
     * @returns {string}
     * @private
     */
    _JsonToUrlString(_object) {

        if(!_object) {
            return
        }

        let _prms = new URLSearchParams(_object)
        return _prms.toString()

    }


}