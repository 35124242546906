import Validity from '../../js/validity.js'
import * as countDown from '../../js/countDown.js'
import * as orderSummary from '../../js/orderSummary.js'
// import * as estimatedArrival from '../../js/estimatedArrival.js'
import * as zip2State from '../../js/zip2State.js'

/**
 * Handles filtering through parameters for storage & extends from Cookie (set & get)
 */
import Parameter from '../../js/parameter'



class Form {

    constructor(host) {

        this.host                             = host || window.location.origin + window.location.pathname
        this.postUrl                          = 'https://api.funnelms.com/payment/initial'
        this._defaultPaymentButtonText        = '<i class="btn__lock icon-lock"></i><span class="hidden-xs">PLACE YOUR ENCRYPTED ORDER NOW</span><span class="visible-xs-inline">Complete My Order</span><i class="btn__arrow icon-arrow-right"></i>'
        this._$shippingForm                   = $('.shipping-form')
        this._$paymentForm                    = $('.payment-form')
        this._$cardNumber                     = $('#card_number')
        this._$creditCardType                 = $('#credit-card-type')
        this._$paymentFormNextButton          = $('.payment-form__next-button')
        this._$processingModal                = $('#processing-modal')
        this._$errorMessage                   = $('.error-message')
        this._$paymentFormFieldCreditCardIcon = $('.payment-form__field--credit-card-icon')

        // Provides access to Cookies & query filtering for data tracking
        this.Parameter

        // Setup Step1 Validation
        this._step1 = new Validity({
            'firstName'       : ['required', 'numbers letters spaces periods hypens'],
            'lastName'        : ['required', 'numbers letters spaces periods hypens'],
            'emailAddress'    : ['required', 'email'],
            'shippingAddress1': ['required'],
            'shippingAddress2': ['not required'],
            'shippingCity'    : ['required', 'numbers letters spaces periods hypens'],
            'shippingState'   : ['required', 'numbers letters spaces periods hypens'],
            'shippingCountry' : ['required', 'numbers letters spaces periods hypens'],
            'phoneNumber'     : ['required', 'numbers letters spaces periods hypens'],
            'shippingZip'     : ['required', 'numbers letters spaces periods hypens'],
        });


        this._step2 = new Validity({
            'creditCardNumber': ['required', 'credit card number'],
            'creditCardType'  : ['required', 'letters'],
            'expirationMonth' : ['required', 'numbers'],
            'expirationYear'  : ['required', 'numbers'],
            'cvv'             : ['required', 'numbers letters']
        });


        // Bind click to next-button
        $(document).on('click', '.shipping-form__next-button', ()=> { this._validateStep1() })

        // Bind click to process payment
        $(document).on('click', '.payment-form__next-button', ()=> { this._validateStep2() })

        // Bind
        $(document).on('blur input keyup change', '[name="creditCardNumber"]', ()=> { this._handleEvent() })


    }

    _scrollToErrorMessage() {
        $('html, body').animate({
            scrollTop: this._$errorMessage.offset().top - 10
        }, 'slow')
    }

    _validateStep1() {

        var _this = this

        // Validate against rules
        this._step1.validate()

        // Check if validation passes
        if (!this._step1.valid()) {
            return this._scrollToErrorMessage()
        }

        //Hide Shipping Form
        this._$shippingForm.fadeOut(100, () => {

            // Show Payment Form
            _this._$paymentForm.fadeIn(1000, () => {

                // Focus on Credit Card field
                _this._$cardNumber.focus()
                // Scroll up
                _this._scrollToErrorMessage()

            });

        });

    }

    _validateStep2() {

        this._step2.validate()

        if (!this._step2.valid()) {
            this._scrollToErrorMessage()
            return;
        }

        // Disable clicked button
        $(this).prop('disabled', true)

        // Display pop-up modal
        // this._$processingModal.modal('show')

        // Combine two objects into FORM_DATA
        this._processPayment(Object.assign(this._step1.data(), this._step2.data()))

    }
    // TODO own file
    _getCreditCardType(_cardNumber) {

        if (/^5[1-5]/.test(_cardNumber)) {
            return 'master'
        } else if (/^4/.test(_cardNumber)) {
            return 'visa'
        } else if (/^3[47]/.test(_cardNumber)) {
            return 'amex'
        } else if (/^6(?:011|5[0-9]{2})[0-9]{3,}$/.test(_cardNumber)) {
            return 'discover'
        } else if (/^35(?:2[89]|[3-8]\d)\d{12}$/.test(_cardNumber)) {
            return 'jcb'
        } else {
            return 'unknown'
        }

    }
    // TODO own file
    _addCreditCardIcon(_type) {

        var _this = this

        switch (_type) {
            case 'master':
                _this._$paymentFormFieldCreditCardIcon.addClass('payment-form__field--mastercard-credit-card')
                break

            case 'visa':
                _this._$paymentFormFieldCreditCardIcon.addClass('payment-form__field--visa-credit-card')
                break

            case 'amex':
                _this._$paymentFormFieldCreditCardIcon.addClass('payment-form__field--amex-credit-card')
                break

            case 'discover':
                _this._$paymentFormFieldCreditCardIcon.addClass('payment-form__field--discover-credit-card')
                break

            default:
                _this._$paymentFormFieldCreditCardIcon.addClass('payment-form__field--unknown-credit-card')
                break
        }
    }
    // TODO own file
    _handleEvent(event) {

        console.log(this._$cardNumber.val())

        // Remove hyphens and get card type
        var _type = this._getCreditCardType(this._$cardNumber.val().replace(/-/g, ''))

        // Add type to hidden field ( to be sent with FORM_DATA )
        this._$creditCardType.val(_type)

        // Adds dashes between 4 digits
        this._$cardNumber.val(this._$cardNumber.val().replace(/\D/g, '').match(/.{1,4}|^$/g).join('-'))

        // Remove classes
        this._$paymentFormFieldCreditCardIcon
            .removeClass('payment-form__field--unknown-credit-card payment-form__field--discover-credit-card payment-form__field--mastercard-credit-card payment-form__field--visa-credit-card payment-form__field--amex-credit-card')

        // Added matching credit card icon to field
        this._addCreditCardIcon(_type)

    }

    /**
     * TODO own file
     * Determine if GTM Container ID available
     * @returns {boolean}
     * @private
     */
    _isGTMContainerIdAvailable() {

        let _foundGTMId = true

        // Check if global variable exists
        if(window.hasOwnProperty('FUNNEL_SETTINGS')) {

            // Check if global variable has property declared
            if(!FUNNEL_SETTINGS.hasOwnProperty('googleTagManagerId')) {
                console.log('FUNNEL_SETTING.googleTagManagerId was not found')
                _foundGTMId = false
            }

        } else {
            // FUNNEL SETTINGS WERE NOT FOUND
            console.log('FUNNEL_SETTINGS were not found')
            _foundGTMId = false
        }

        return _foundGTMId

    }
    // TODO own file
    _pushDataLayer(transaction, product, cb) {

        if(!transaction || !product) {
            return cb()
        }

        window.dataLayer = window.dataLayer || []

        window.dataLayer.push({
            'event'        : 'eec.purchase',
            'eventCallback': function (GTM_CONTAINER_ID) {
                // Check the callback is being fired for the correct container id
                if(GTM_CONTAINER_ID === FUNNEL_SETTINGS.googleTagManagerId) {
                    return cb(GTM_CONTAINER_ID)
                }
            },
            'eventTimeout' : 4000,
            // The quantity of the given product purchased.
            'quantity'     : 1,
            // The price of one item. Same formatting instructions as with revenue.
            'price'        : product.price,
            'transactionId': transaction.orderId,
            'testing'      : transaction.test,
            'ecommerce'    : {
                'currencyCode': 'USD',
                'purchase'    : {
                    // The unique order ID of the transaction.
                    // Should match the actual ID of the order.
                    'actionField': {
                        // Transaction ID
                        'id'         : transaction.orderId,
                        // Extra details about where the purchase happened.
                        'affiliation': transaction.gatewayDescriptor,
                        /*
                         * Total transaction value. You can include tax and shipping,
                         * or you can choose to send the revenue without tax and shipping.
                         * The value must not include anything else except number separated by
                         * a decimal point. Don’t use a comma as the separator, and don’t include
                         * any currency symbols.
                         */
                        'revenue'    : product.price,
                        // Tax paid. Same formatting instructions as with revenue.
                        'tax'        : transaction.orderSalesTaxAmount,
                        // Cost of shipping. Same formatting instructions as with revenue.
                        'shipping'   : '0.00',
                    },
                    'products'   : [{
                        // The SKU of the product.
                        'id'      : product.sku,
                        // The name of the product.
                        'name'    : product.name,
                        // The brand name of the product.
                        'brand'   : FUNNEL_SETTINGS.brandName,
                        // The quantity of the given product purchased.
                        'quantity': 1,
                        // The price of one item. Same formatting instructions as with revenue.
                        'price'   : product.price
                    }]
                }
            }
        })

    }

    _processPayment(_formData) {

        var _this = this;

        // Add host to _formData payload
        _formData.host = this.host
        // Add expirationDate
        _formData.expirationDate = $('[name=expirationMonth]').val() + $('[name=expirationYear]').val()
        // Create tracking object
        _formData.tracking = {}

        _this.Parameter = new Parameter()

        // Tracking Parameters
        let _trackingParameters = _this.Parameter._getCookie('fms_parameters')
        if(_trackingParameters) {
            _formData.tracking = JSON.parse(_trackingParameters)
            // Add URL query to notes for backward compatibility
            _formData.tracking.notes = _this.Parameter._JsonToUrlString(_formData.tracking)
        }


        console.log(_formData)


        // TODO make a boolean variable to stop double form submissions
        _this._$paymentFormNextButton.prop('disabled', true).html('Processing...')

        // TODO update to fetch, need polyfillers for fetch & promises
        $.ajax({
            type       : 'post',
            url        : this.postUrl,
            data       : JSON.stringify(_formData),
            contentType: 'application/json; charset=utf-8',
            dataType   : 'json',
            success: function (_response) {

                // Check if Token was provided in the response from server
                if (!_response.hasOwnProperty('token')) {
                    // Show Error Message
                    _this._$errorMessage.css({'display': 'block'}).html('FATAL: Token not received from response.')
                    // Scroll page to Error Message
                    _this._scrollToErrorMessage()
                    return;
                }


                try {

                    // Check if response was successful
                    if (_response.error == true) {

                        // Enable Payment Button
                        _this._$paymentFormNextButton.prop('disabled', false).html(this._defaultPaymentButtonText)

                        // Hide Processing Modal
                        // _this._$processingModal.modal('hide')

                        // Show Error Message
                        _this._$errorMessage.css({'display': 'block'}).html(_response.errorMessage)

                        // Scroll page to Error Message
                        _this._scrollToErrorMessage()

                        return

                    }



                    // Set Token data into a Cookie
                    _this.Parameter._setCookie('fms_serverless', _response.token, 7)

                    // Determine if GTM Container ID is available
                    if(_this._isGTMContainerIdAvailable()) {

                        // Failsafe, if pushDataLayer doesn't get a response we redirect client
                        setTimeout(()=> {
                            window.location.href = _response.nextPagePath
                        }, 6000)


                        // Push Payload to dataLayer
                        _this._pushDataLayer(_response.transaction, _response.product, ()=> {
                            window.location.href = _response.nextPagePath
                        })

                    } else {
                        window.location.href = _response.nextPagePath
                    }



                } catch (err) {
                    console.error(err)
                    _this._$paymentFormNextButton.prop('disabled', false).html(this._defaultPaymentButtonText)
                }


            },
            // TODO own file
            error  : function (jqXHR, textStatus, errorThrow) {

                let errorMessage

                // Exception handling
                switch (textStatus) {
                    case ('parsererror'):
                        errorMessage = 'Requested JSON parse failed.'
                        break;
                    case ('timeout'):
                        errorMessage = 'Time out error.'
                        break;
                    case ('abort'):
                        errorMessage = 'Internal Server Error [500].'
                        break;
                    default:
                        if (jqXHR.hasOwnProperty('responseText')) {
                            errorMessage = 'Uncaught Error.n' + jqXHR.responseText
                        }
                }

                // StatusCode Errors
                switch (jqXHR.status) {
                    case (0):
                        errorMessage = 'FATAL: Not connect.n Verify Network.'
                        break;
                    case (404):
                        errorMessage = 'FATAL: Requested page not found. [404]'
                        break;
                    case (500):
                        errorMessage = jqXHR.responseJSON.errorMessage || 'FATAL: Internal Server Error [500].'
                        break;
                }

                console.log(jqXHR)

                _this._$errorMessage.css({'display': 'block'}).html(errorMessage)
                // Scroll page to Error Message
                _this._scrollToErrorMessage()

                // Change Button text back to default
                _this._$paymentFormNextButton.prop('disabled', false).html(_this._defaultPaymentButtonText)

            }
        });

    }

}
new Form()