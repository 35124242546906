export default class Cookie {

    constructor() {
        console.log('I AM PARENT AND I WAS CALLED')
    }

    _setCookie(_name, _value, _days) {

        let _expires = ''

        if(_days) {
            let _date = new Date()
            _date.setTime(_date.getTime() + (_days * 24 * 60 * 60 * 1000))
            _expires =`; expires=${_date.toUTCString()}`
        }

        console.log(`_name ${_name}`)
        console.log(`_value ${_value}`)
        console.log(`_expires ${_expires}`)

        document.cookie = `${_name}=${_value || ''}${_expires}; path=/`


    }


    _getCookie(_cookieName) {

        let _name          = `${_cookieName}=`
        let _decodedCookie = decodeURIComponent(document.cookie)
        let _ca            = _decodedCookie.split(';')


        for(let i = 0; i < _ca.length; i++) {

            let c = _ca[i]

            while (c.charAt(0) == ' ') {
                c = c.substring(1)
            }

            if (c.indexOf(_name) == 0) {
                return c.substring(_name.length, c.length)
            }

        }
        return ''
    }


}

